<template>
  <div>
    <v-alert
      :type="actionClass"
      class="mr-15"
      v-if="message"
      dismissible
    >
      {{ message }}
     </v-alert>
    <PlaceHolder
      v-if="orders.length === 0"
      :src="require('@/assets/img/svg/no-notifications.svg')"
      title=" Nothing Here"
      text=" No orders found. Try Search"
    />
    <div v-else>
      <v-list-item-group v-model="model">
        <template v-for="(order, index) in orders" class=" ">
          <v-list-item :key="order.id" link :value="order" class=""  >
            <v-list-item-content @click="routerPass(order)">
              <v-list-item-title>{{ order.topic === null ? '( no topic)': order.topic }}</v-list-item-title>
              <v-list-item-subtitle class="caption">
                {{ order.id }} | {{ order.pages }} pages | {{ order.assignment_type }} |
                {{ order.language }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-btn
              class="mt-3"
              :color="orderStatusColor(order.order_status)"
              x-small
              @click="routerPass(order)"
            >
              {{ order.order_status }}
            </v-btn>

            <div class="text-body-2 d-flex flex-column ml-3" style="width:20%">
                <span class="align-self-end">
                  ~$ {{ order.amount }}</span
                >
                <span class="align-self-end" :class="{'red--text': timeErrorStatus(order.final_date)}">{{ formatOrderDate(order.final_date) }}</span>
            </div>

            <v-list-item-action class="fr">
              <div>
                <v-btn icon outlined tile class="mt-2 rounded-lg" text @click="deleteOrder(order.id, index)">
                  <i class="far fa-trash-alt"></i>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider class="my-0" :key="index"></v-divider>
        </template>
      </v-list-item-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from '@/mixins/validation_mixin';
import timezoneMxn from '@/mixins/timezone_mixin'

export default {
  name: "OrderComponent",
  components: {
    PlaceHolder: () => import("@/components/core/PlaceHolder")
  },
  mixins: [colorMxn, validationMxn, timezoneMxn],
  props: ["orderStatus"],
  data() {
    return {
      orders: [],
      model: "",
      actionClass: 'success',
      message: '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['getSession']),
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      const payload = {
        page: 1,
        order_status: this.orderStatus,
        customer_id: this.getSession.customer.id,
      };

      const fullPayload = {
        params: payload,
        endpoint: '/customer-orders'
      };
      const response = await this.performGetActions(fullPayload);
      this.orders = response.data;
    },
    async deleteOrder(id, index) { 
      this.loading = true;
      const fullPayload = {
        endpoint: `/orders/${id}`,
      }
      const response = await this.performDeleteActions(fullPayload);
      this.actionClass = response.data.status === 'success' ? 'success': 'error';
      this.message = response.data.message;
      this.loading = false;
      
      if (response.data.status === 'success') {
        this.orders.splice(index, 1);
      }
    },
    routerPass(order) {
      switch (order.order_status) {
        case 'draft': 
          this.$router.push(`/order/draft/${order.id}`)
          break;
        case 'open': 
          this.$router.push(`/dashboard/order/${order.id}/assign`)
          break;
        case 'bidding': 
          this.$router.push(`/dashboard/order/${order.id}/assign`)
          break;
        case ('completed' || 'in-progress'): 
          this.$router.push(`/dashboard/order/${order.id}`)
          break;
        default: 
          this.$router.push(`/dashboard/order/${order.id}`)
          break;
      }
      
    }
  }
};
</script>

<style scoped></style>
